<template>
  <v-dialog :value="true" persistent max-width="640">
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-card-plus</v-icon>
          Kártya létrehozása
          <v-spacer />
          <v-btn icon @click="$emit('close')">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <div class="text-h6 ma-2">Birtokos adatai</div>

          <dynamic-field
            v-model="owner.name"
            component-path="uninet-vuetify/src/components/DatetimePicker/DatetimePicker.vue"
            v-bind="{ filled: '', label: 'tesztelek' }"
          />

          <v-autocomplete
            label="Birtokos"
            :items="owners"
            v-model="card.owner_id"
            filled
            item-text="name"
            item-value="element_id"
            class="ma-2"
            hide-details
            :value-comparator="$config.comparators.isEqual"
          />
          <template v-if="card.owner_id == 0">
            <v-row no-gutters>
              <v-col cols="12" sm="8">
                <v-text-field
                  label="Kártyabirtokos neve"
                  filled
                  v-model="owner.name"
                  hint="Kötelező"
                  class="ma-2"
                  persistent-hint
                  hide-details
                />
              </v-col>
              <v-col>
                <v-datetime-picker
                  label="Lejárat"
                  v-model="owner.validity_end"
                  filled
                  :time-picker="false"
                  class="ma-2"
                  hide-details
                />
              </v-col>
            </v-row>

            <div v-if="ownerDetails">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <v-text-field
                    label="Cím"
                    filled
                    v-model="owner.address"
                    class="ma-2"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-datetime-picker
                    label="Születési idő"
                    v-model="owner.birth_date"
                    filled
                    :time-picker="false"
                    class="ma-2"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Email cím"
                    filled
                    v-model="owner.email"
                    class="ma-2"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Telefonszám"
                    filled
                    v-model="owner.phone"
                    class="ma-2"
                    hide-details
                    v-mask="$config.inputMasks.phoneNumber"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="d-flex">
              <v-spacer />
              <v-btn small text depressed @click="ownerDetails = !ownerDetails">
                <v-icon left>{{ ownerDetails ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                <div v-if="ownerDetails">További adatok elrejtése</div>
                <div v-else>További adatok megjelenítése</div>
              </v-btn>
              <v-spacer />
            </div>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <div class="text-h6 ma-2">Kártya adatai</div>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                label="RFID"
                v-model="card.rf_id"
                filled
                placeholder="Érintse a kártyát az olvasóhoz"
                hint="Kötelező"
                v-mask="$config.inputMasks.rfId"
                v-uppercase
                persistent-hint
                class="ma-2"
                hide-details
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Kártyaszám"
                v-model="card.card_number"
                filled
                hint="Kötelező"
                persistent-hint
                v-mask="$config.inputMasks.cardNumber"
                class="ma-2"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-autocomplete
                label="Hozzáférési szintek"
                v-model="card.access_level_ids"
                :items="accessLevels"
                filled
                multiple
                clearable
                chips
                item-text="name"
                item-value="element_id"
                class="ma-2"
                hide-details
              />
            </v-col>
            <v-col>
              <v-combobox
                label="Rendszámok"
                v-model="card.plates"
                filled
                multiple
                clearable
                chips
                class="ma-2"
                hide-details
                v-mask="$config.inputMasks.plateNumber"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                v-show="hasFeature('CARD_TEMPLATE')"
                label="Sablon"
                v-model="card.template_id"
                :items="cardTemplates"
                filled
                item-text="name"
                item-value="id"
                class="ma-2"
                hide-details
                :value-comparator="$config.comparators.isEqual"
            /></v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" large depressed @click="save" :loading="saving">
            <v-icon left>mdi-check</v-icon>
            Mentés
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { cardReader } from '@/mixins';

export default {
  mixins: [cardReader],

  data() {
    return {
      ownerDetails: false,
      owner: {},
      card: {
        owner_id: 0,
        access_level_ids: [],
      },
      loadingOwners: false,
      saving: false,
    };
  },

  destroyed() {
    this.closeCardReaderSocket();
  },

  mounted() {
    this.watchCardReader(async (event) => {
      this.$set(this.card, 'rf_id', event.data || '');
    });
  },

  methods: {
    async save() {
      this.saving = true;

      const response = await this.$http.post('cards/quick-create', {
        owner: this.owner,
        card: this.card,
      });

      this.saving = false;
    },
  },

  computed: {
    selectedTemplate() {
      for (const template of this.cardTemplates) {
        if (this.card.template_id == template.id) {
          return template;
        }
      }
      return {};
    },
  },

  asyncComputed: {
    owners: {
      async get() {
        const response = await this.$http.post('/card-owners/list', { limit: 99999 });
        return [{ name: 'Új kártyabirtokos létrehozása', element_id: 0 }, ...response.card_owners];
      },
      default: [],
    },

    accessLevels: {
      async get() {
        const response = await this.$http.post('access-levels/list', { limit: 99999 });
        return response.access_levels;
      },
      default: [],
    },

    cardStatuses: {
      async get() {
        const response = await this.$http.post('card-statuses/list', { limit: 99999 });
        this.$set(this.card, 'status_id', response.default_status.id);
        return response.card_statuses;
      },
      default: [],
    },

    cardTemplates: {
      async get() {
        const response = await this.$http.post('card-templates/list', { limit: 99999 });
        this.$set(this.card, 'template_id', response.default_template.id);

        const defaultAccessLevelAliases = JSON.parse(
          response.default_template.default_access_levels
        );
        let accessLevelIds = this.accessLevels
          .filter((e) => defaultAccessLevelAliases.includes(e.alias))
          .map((e) => e.element_id);
        this.$set(this.card, 'access_level_ids', accessLevelIds);
        return response.card_templates;
      },
      default: [],
      lazy: true,
      shouldUpdate() {
        return this.accessLevels.length;
      },
    },
  },
};
</script>
